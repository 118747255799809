<template>
  <div data-cy="asset-accounts-data-quality-page">
    <TabTitle class="mb-4" icon="fa-ballot-check" action-position="right">
      <template>Data Quality</template>
      <template #sub-title>Find missing, incomplete or unusual data. Click a month to see more details.</template>
    </TabTitle>
    <AccountHeatmap :asset-id="$route.params.id" :account="selectedAccount" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

import AccountHeatmap from '@/components/AccountHeatmap';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AssetAccountDataQuality',
  components: {
    AccountHeatmap,
    TabTitle
  },
  data() {
    return {
      showAdd: false
    };
  },
  computed: {
    ...mapGetters({
      accounts: 'asset/accounts'
    }),
    selectedAccount() {
      const account = (this.accounts || []).find(a => a._id === this.$route.params.accountId);

      return account;
    }
  }
};
</script>
