<template>
  <div>
    <Spinner v-if="loadingAction.getAssetAnalytics.accountHeatmap" class="push" />
    <div v-else>
      <div class="table table-borderless table-sm heatmap push">
        <thead>
          <tr>
            <th></th>
            <th v-for="(item, index) in monthlyInvoiceData" :key="index">
              <div class="heatmap-header">{{ item.date | date('MMM YYYY') }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p class="font-size-h4 font-w600 mb-0"><i class="fa fa-file-invoice fa-fw"></i> Invoices</p>
            </td>
            <td
              v-for="(item, index) in monthlyInvoiceData"
              :key="index"
              ref="tooltip"
              class="heatmap-cell"
              :class="{
                'heatmap-cell--danger': item.coverage < 50,
                'heatmap-cell--success': item.coverage === 100,
                'heatmap-cell--warning': (item.coverage >= 50 && item.coverage < 100) || item.coverage > 100
              }"
              :title="`${item.coverage.toFixed(0)}%`"
              @click="onClickMonth(item.date)"
            >
              <i v-if="isSelectedMonth(item.date)" class="fa fa-times text-white"></i>
            </td>
          </tr>
        </tbody>
      </div>
      <SectionTitle>{{ drilldownMonth }}</SectionTitle>
      <div class="row">
        <div class="col-xl-6">
          <DataTable
            :items="detailedData"
            :no-paging="true"
            :columns="[
              { code: 'date', name: 'Date', sortable: false },
              { code: 'coverage', name: 'Coverage', sortable: false },
              { code: 'invoiceNumbers', name: 'Invoice Number(s)', sortable: false }
            ]"
          >
            <template #date="{ data: item }">
              <strong>{{ item.date | date }}</strong>
            </template>
            <template #coverage="{ data: item }">
              <span v-if="item.invoices.length === 1" class="text-success">
                <i class="fa fa-check-circle fa-fw"></i>
              </span>
              <span v-else-if="item.invoices.length === 0" class="text-danger">
                <i class="fa fa-times-circle fa-fw"></i>
              </span>
              <span v-else class="text-warning">
                <i class="fa fa-exclamation-circle fa-fw"></i>
              </span>
            </template>
            <template #invoiceNumbers="{ data: item }">
              {{ item.invoices.map(inv => inv.values.invoiceNumber).join(', ') }}
            </template>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import Spinner from './Spinner';
import DataTable from '@/components/DataTable';
import SectionTitle from '@/components/base/SectionTitle';

export default {
  name: 'AccountHeatmap',
  components: {
    DataTable,
    SectionTitle,
    Spinner
  },
  props: {
    assetId: {
      type: String,
      required: true
    },
    account: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: [],
      startDate: moment().startOf('month').subtract(1, 'year'),
      endDate: moment().endOf('month'),
      drilldownMonth: moment().startOf('month').subtract(1, 'year').format('MMM YYYY')
    };
  },
  computed: {
    ...mapGetters({
      loadingAction: 'asset/loadingAction'
    }),
    monthlyInvoiceData() {
      if (!this.data || this.data.length === 0) return [];

      const accountData = this.data.find(item => item.accountId === this.account._id);
      if (!accountData || !accountData.dates) return [];

      const months = Array.from(Array(this.endDate.diff(this.startDate, 'month') + 1).keys()).map((j, i) =>
        moment(this.startDate).startOf('month').add(i, 'month')
      );

      return months.map(date => {
        const recordsInMonth = accountData.dates.filter(item => moment(item.date).format('MM-YYYY') === date.format('MM-YYYY'));
        const daysInMonth = date.daysInMonth();
        const invoicesInMonth = recordsInMonth.reduce((sum, record) => sum + record.invoices.length, 0);
        return {
          date,
          coverage: (invoicesInMonth / daysInMonth) * 100
        };
      });
    },
    detailedData() {
      const accountData = this.data.find(item => item.accountId === this.account._id);
      if (!accountData || !accountData.dates) return [];

      const records = accountData.dates.filter(
        item => moment(item.date).format('MM-YYYY') === moment(this.drilldownMonth, 'MMM YYYY').format('MM-YYYY')
      );

      return records;
    }
  },
  async mounted() {
    this.data = await this.getAssetAnalytics({
      id: this.assetId,
      type: 'accountHeatmap',
      options: {
        type: 'account-heatmap',
        accountId: this.account._id,
        accountType: 'electricity',
        startDate: this.startDate.format('DD/MM/YYYY'),
        endDate: this.endDate.format('DD/MM/YYYY')
      }
    });
    setTimeout(() => {
      jQuery(this.$refs.tooltip).tooltip({
        placement: 'top'
      });
    }, 2000);
  },
  methods: {
    ...mapActions({
      getAssetAnalytics: 'asset/getAssetAnalytics'
    }),
    onClickMonth(month) {
      this.drilldownMonth = moment(month).format('MMM YYYY');
    },
    isSelectedMonth(date) {
      return this.drilldownMonth === date.format('MMM YYYY');
    }
  }
};
</script>
<style lang="scss">
.heatmap {
  border-spacing: 10px;
  cursor: pointer;
  text-align: center;
}
.heatmap-header {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  white-space: pre;
}
.heatmap-cell {
  border-radius: 2px;
}
.heatmap-cell--success {
  background-color: rgb(130, 181, 75);
}
.heatmap-cell--warning {
  background-color: rgb(255, 177, 25);
}
.heatmap-cell--danger {
  background-color: rgb(224, 79, 26);
}
</style>
