var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-accounts-data-quality-page"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-ballot-check",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("Find missing, incomplete or unusual data. Click a month to see more details.")];
      },
      proxy: true
    }])
  }, [[_vm._v("Data Quality")]], 2), _c('AccountHeatmap', {
    attrs: {
      "asset-id": _vm.$route.params.id,
      "account": _vm.selectedAccount
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }